import { Button } from "react-bootstrap";
import {
  checkIsMatchTBD,
  isPrizeConfigValid,
  isPublishButtonDisable,
  isSaveButtonDisabled,
} from "../teamConfigHelper";
import Itooltip from "../../../../common/Itooltip/Itooltip";
import {
  Action,
  ContestTypeEnum,
  Match,
  MatchConfigModel,
  MatchStatus,
  PrizeTypeEnum,
} from "../../../../common/constants";
import { useMemo, useState } from "react";
import { CustomStatusCode, HttpStatus } from "../../../../utils/constants";
import {
  downloadMatchUserTeamsApi,
  downloadPlayerReportApi,
  publishPrizeAndFuttyPlayer,
  savePrizeAndFuttyPlayer,
  triggerDisbursePrize,
  triggerScoreCalculation,
} from "../../../service";
import { getCommonError } from "../../../../common/message";
import { convertBase64ToBuffer } from "../../../../utils/transformation";
import * as FileSaver from "file-saver";
import ConfirmationModal from "../../../../common/components/Modal/ConfirmationModel";
import { getModelTypeConfirmationMessage } from "../../PlayersConfig/playerHelper";
import {
  showFailureToaster,
  showSuccessToaster,
} from "../../../../utils/apiUtil";
import { useLocation } from "react-router-dom";

export const TeamConfigButtons = ({
  match,
  isPubishedMatch,
  saveLoader,
  publishLoader,
  prizeList,
  setShowToaster,
  team1Color,
  team2Color,
  futyStar,
  playerList,
  setSaveLoader,
  setPublishLoader,
  rewardType = PrizeTypeEnum.ABSOLUTE,
}) => {
  const [modelType, setModelType] = useState(
    MatchConfigModel.PUBLISH_UNPUBLISH,
  );
  const [contestType, setContestType] = useState(ContestTypeEnum.TG_FANTASY);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [isDownloadingMatchUsersTeams, setIsDownloadingMatchUsersTeams] =
    useState(false);
  const [distributePrizeLoader, setDistributePrizeLoader] = useState(false);
  const [calScoreLoader, setCalScoreLoader] = useState(false);
  const isMatchTBD = checkIsMatchTBD(match?.teamId1, match?.teamId2);
  const isDisableSaveButton = isSaveButtonDisabled(
    futyStar,
    prizeList,
    match?.localStartTime,
    playerList,
  );
  const isMatchLiveOrCompleted = useMemo(() => {
    const matchStatus = match?.status?.toLowerCase();
    return (
      matchStatus === MatchStatus.LIVE || matchStatus === MatchStatus.COMPLETED
    );
  }, [match?.status]);
  const isPrizeNotDisbursed = useMemo(() => {
    const matchStatus = match?.status?.toLowerCase();
    return (
      matchStatus === MatchStatus.COMPLETED && match?.prizeDisbursed === false
    );
  }, [match]);
  const isDisablePublishButton: { disable: boolean; message: string } =
    isPublishButtonDisable(match, futyStar, prizeList, playerList);
  const isDisablePrizeDisburseButton =
    !isPubishedMatch || distributePrizeLoader || !isPrizeNotDisbursed;
  const isDisableCalculateScoreButton =
    !isPubishedMatch || !isMatchLiveOrCompleted || calScoreLoader;

  const location = useLocation();
  const isTgMatches = location.pathname.includes("telegram");

  const handleSave = async () => {
    setSaveLoader(true);
    const payload = {
      futyStars: futyStar?.map((elem) => elem.playerId),
      prizes: prizeList?.sort((a, b) => a?.from - b?.from),
      team1Color: team1Color?.value || "",
      team2Color: team2Color?.value || "",
      contestType: isTgMatches
        ? ContestTypeEnum.TG_FANTASY
        : ContestTypeEnum.MATCH,
      rewardType,
    };
    const resData: any = await savePrizeAndFuttyPlayer(payload, {
      matchId: match?.id,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setSaveLoader(false);
      setShowToaster(
        showSuccessToaster(resData?.message || "Successfully saved the data"),
      );
    } else {
      setSaveLoader(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const downloadMatchUsersTeams = async () => {
    setIsDownloadingMatchUsersTeams(true);
    const resData: any = await downloadMatchUserTeamsApi({
      matchId: match?.id,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsDownloadingMatchUsersTeams(false);
      const buffer = convertBase64ToBuffer(resData?.data);
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(
        blob,
        `leaderboard_${match?.teamId1?.shortCode}_${
          match?.teamId2?.shortCode
        }_${Date.now()}.xlsx`,
      );
    } else {
      setIsDownloadingMatchUsersTeams(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const handlePublish = async () => {
    setPublishLoader(true);
    const resData: any = await publishPrizeAndFuttyPlayer(
      { publish: !isPubishedMatch, contestType },
      { matchId: match?.id },
    );
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster(
        showSuccessToaster(
          Match.configure.publishOrUnpublishDetailsMessage(isPubishedMatch),
        ),
      );
      setPublishLoader(false);
    } else {
      setPublishLoader(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const calculateScore = async () => {
    setCalScoreLoader(true);
    const resData: any = await triggerScoreCalculation(
      {},
      { matchId: match?.id },
    );
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setCalScoreLoader(false);
      setShowToaster(
        showSuccessToaster(
          resData?.message ||
            "Successfully submitting request for score calculation",
        ),
      );
    } else {
      setCalScoreLoader(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const disbursePrize = async () => {
    setDistributePrizeLoader(true);
    const resData: any = await triggerDisbursePrize(
      {
        contestType: isTgMatches
          ? ContestTypeEnum.TG_FANTASY
          : ContestTypeEnum.MATCH,
      },
      { matchId: match?.id },
    );
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setDistributePrizeLoader(false);
      setShowToaster(
        showSuccessToaster(
          resData?.message ||
            "Successfully submitting request for distribute prize",
        ),
      );
    } else {
      setDistributePrizeLoader(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  const downloadPlayerReport = async () => {
    setIsDownloadingReport(true);
    const resData: any = await downloadPlayerReportApi(
      {},
      {
        matchId: match?.id,
      },
    );
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsDownloadingReport(false);
      const buffer = convertBase64ToBuffer(resData?.data);
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(
        blob,
        `scores_${match?.teamId1?.shortCode}_${
          match?.teamId2?.shortCode
        }_${Date.now()}.xlsx`,
      );
    } else {
      setIsDownloadingReport(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster(showFailureToaster(getCommonError(resData?.message)));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end mt-4" style={{ zIndex: 10 }}>
        {!isPubishedMatch && (
          <div
            className={`button-with-tooltip ${
              saveLoader || publishLoader || isMatchTBD ? "disabled" : ""
            }`}
          >
            <Button
              className="save-button"
              disabled={saveLoader || publishLoader || isMatchTBD}
              onClick={() => {
                const prizeConfig = isPrizeConfigValid(
                  prizeList?.sort((a, b) => a?.from - b?.from),
                );
                if (!isPubishedMatch && !isMatchTBD) {
                  if (isDisableSaveButton.disable)
                    setShowToaster(
                      showFailureToaster(isDisableSaveButton.message),
                    );
                  else if (!prizeConfig.isValid)
                    setShowToaster(showFailureToaster(prizeConfig.message));
                  else if (!team1Color?.value || !team2Color?.value) {
                    setShowToaster(
                      showFailureToaster(
                        !team1Color?.value
                          ? "Please choose team 1 color"
                          : "Please choose team 2 color",
                      ),
                    );
                  } else handleSave();
                }
              }}
            >
              {saveLoader ? Action.saving : Action.save}
            </Button>
            <Itooltip
              placement="top"
              tooltipId={"save-match-config"}
              tooltipContent={Match.configure.saveContent}
            />
          </div>
        )}
        {isPubishedMatch && (
          <div
            className={`button-with-tooltip ${
              isDownloadingMatchUsersTeams || !isMatchLiveOrCompleted
                ? "disabled"
                : ""
            }`}
          >
            <Button
              className="publish-button"
              onClick={() => {
                if (!isDownloadingMatchUsersTeams && isMatchLiveOrCompleted) {
                  downloadMatchUsersTeams();
                }
              }}
              disabled={isDownloadingMatchUsersTeams || !isMatchLiveOrCompleted}
            >
              {isDownloadingMatchUsersTeams
                ? Action.downloadingLeaderboard
                : Action.downloadLeaderboard}
            </Button>
            <Itooltip
              placement="top"
              tooltipId={"download-match-teams"}
              tooltipContent={Match.configure.downloadLeaderboardContent}
            />
          </div>
        )}
        {isPubishedMatch && !distributePrizeLoader && (
          <div
            className={`button-with-tooltip ${
              isDisablePrizeDisburseButton ? "disabled" : ""
            }`}
          >
            <Button
              className="publish-button"
              onClick={() => {
                if (!isDisablePrizeDisburseButton) {
                  setModelType(MatchConfigModel.DISBURSE_PRIZE);
                  setShowConfirmationModal(true);
                }
              }}
              disabled={isDisablePrizeDisburseButton}
            >
              {distributePrizeLoader
                ? Action.disbursingPrize
                : Action.disbursePrize}
            </Button>
            <Itooltip
              placement="top"
              tooltipId={"disburse-prize"}
              tooltipContent={Match.configure.prizeDisbursedContent}
            />
          </div>
        )}
        {isPubishedMatch && (
          <div
            className={`button-with-tooltip ${
              isDownloadingReport || !isMatchLiveOrCompleted ? "disabled" : ""
            }`}
          >
            <Button
              className="publish-button"
              onClick={() => {
                if (!isDownloadingReport && isMatchLiveOrCompleted) {
                  downloadPlayerReport();
                }
              }}
              disabled={isDownloadingReport || !isMatchLiveOrCompleted}
            >
              {isDownloadingReport
                ? Action.downloadingScore
                : Action.downloadScore}
            </Button>
            <Itooltip
              placement="top"
              tooltipId={"download-report"}
              tooltipContent={Match.configure.downloadReportContent}
            />
          </div>
        )}
        {isPubishedMatch && (
          <div
            className={`button-with-tooltip ${
              isDisableCalculateScoreButton ? "disabled" : ""
            }`}
          >
            <Button
              className="publish-button"
              onClick={() => {
                if (isMatchLiveOrCompleted && !calScoreLoader) {
                  setModelType(MatchConfigModel.SCORE_CALCULATION);
                  setShowConfirmationModal(true);
                }
              }}
              disabled={isDisableCalculateScoreButton}
            >
              {calScoreLoader ? Action.calculatingScore : Action.calculateScore}
            </Button>
            <Itooltip
              placement="top"
              tooltipId={"calculate-score"}
              tooltipContent={Match.configure.calculateScoreContent}
            />
          </div>
        )}

        {isTgMatches ? (
          <div
            className={`button-with-tooltip ${
              saveLoader || publishLoader || isMatchTBD ? "disabled" : ""
            }`}
          >
            <Button
              className="publish-button"
              onClick={() => {
                if (!isMatchTBD) {
                  if (isDisablePublishButton.disable)
                    setShowToaster(
                      showFailureToaster(isDisablePublishButton.message),
                    );
                  else {
                    setModelType(MatchConfigModel.PUBLISH_UNPUBLISH);
                    setContestType(ContestTypeEnum.TG_FANTASY);
                    setShowConfirmationModal(true);
                  }
                }
              }}
              disabled={saveLoader || publishLoader || isMatchTBD}
            >
              {isPubishedMatch
                ? publishLoader
                  ? Action.unPublishingTg
                  : Action.unPublishTg
                : publishLoader
                ? Action.publishingTg
                : Action.publishTg}
            </Button>
            <Itooltip
              placement="top"
              tooltipId={"publish"}
              tooltipContent={
                isPubishedMatch
                  ? Match.configure.unpublishContentTg
                  : Match.configure.publishContentTg
              }
            />
          </div>
        ) : (
          <div
            className={`button-with-tooltip ${
              saveLoader || publishLoader || isMatchTBD ? "disabled" : ""
            }`}
          >
            <Button
              className="publish-button"
              onClick={() => {
                if (!isMatchTBD) {
                  if (isDisablePublishButton.disable)
                    setShowToaster(
                      showFailureToaster(isDisablePublishButton.message),
                    );
                  else {
                    setModelType(MatchConfigModel.PUBLISH_UNPUBLISH);
                    setContestType(ContestTypeEnum.MATCH);
                    setShowConfirmationModal(true);
                  }
                }
              }}
              disabled={saveLoader || publishLoader || isMatchTBD}
            >
              {isPubishedMatch
                ? publishLoader
                  ? Action.unPublishing
                  : Action.unPublish
                : publishLoader
                ? Action.publishing
                : Action.publish}
            </Button>
            <Itooltip
              placement="top"
              tooltipId={"publish"}
              tooltipContent={
                isPubishedMatch
                  ? Match.configure.unpublishContent
                  : Match.configure.publishContent
              }
            />
          </div>
        )}
      </div>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={() =>
          getModelTypeConfirmationMessage(modelType, isPubishedMatch)
        }
        handleClick={() => {
          setShowConfirmationModal(false);
          if (modelType === MatchConfigModel.PUBLISH_UNPUBLISH) handlePublish();
          else if (modelType === MatchConfigModel.SCORE_CALCULATION)
            calculateScore();
          else disbursePrize();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Ok"
      />
    </>
  );
};
