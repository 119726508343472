import { MenuItems } from "../common/constants";
const handleUnAuthorisedRoute = (userRole, route) => {
  return route;
};

export const CENTRAL_ADMIN = [
  {
    name: MenuItems.stats,
    // svg: <Venue />,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/"),
    subMenu: [],
  },
  {
    name: MenuItems.telegramStats,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/telegram-stats"),
    subMenu: [],
  },
  {
    name: MenuItems.leagues,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/leagues"),
    disabled: false,
    subMenu: [],
  },
  {
    name: MenuItems.matches,
    // svg: <Home />,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/matches"),
    disabled: false,
    subMenu: [
      {
        name: "Normal Matches",
        route: "/matches/normal-matches",
        disabled: false,
      },
      {
        name: "Telegram Matches",
        route: "/matches/telegram-matches",
        disabled: false,
      },
    ],
  },
  {
    name: MenuItems.teams,
    // svg: <Home />,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/teams"),
    disabled: false,
    subMenu: [],
  },
  {
    name: MenuItems.players,
    // svg: <Venue />,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/players"),
    subMenu: [],
  },
  {
    name: MenuItems.futyPoints,
    // svg: <Venue />,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/futy-points"),
    subMenu: [],
  },
  {
    name: MenuItems.rewards,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/rewards"),
    subMenu: [],
  },
  {
    name: MenuItems.referrals,
    // svg: <Venue />,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/referrals"),
    subMenu: [],
  },
  {
    name: MenuItems.pushNotification,
    // svg: <Venue />,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/push-notification"),
    subMenu: [],
  },
  {
    name: MenuItems.userManagement,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/manage-user"),
    subMenu: [],
  },
  {
    name: MenuItems.userIdentity,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/user-identity"),
    subMenu: [],
  },
  {
    name: MenuItems.quiz,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/quiz"),
    subMenu: [],
  },
  {
    name: MenuItems.goalfest,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/goalfest"),
    subMenu: [],
  },
  {
    name: MenuItems.tasks,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/tasks"),
    subMenu: [
      {
        name: "Daily Login",
        route: "/tasks/daily-login",
        disabled: false,
      },
      {
        name: "Daily Tasks",
        route: "/tasks/daily-tasks",
        disabled: false,
      },
      {
        name: "Milestone Tasks",
        route: "/tasks/milestone-tasks",
        disabled: false,
      },
      {
        name: "Welcome Tasks",
        route: "/tasks/welcome-tasks",
        disabled: false,
      },
    ],
  },
];
